import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import Header from "../../common/Header";
import CreateQuestModal from "./CreateQuestModal";
import {
  MdDelete,
  MdFileCopy,
  MdHome,
  MdOpenInFull,
  MdOutlineMoreVert,
  MdSearch,
  MdList
} from "react-icons/md";
import { useNavigate } from "react-router";
import { cypherService } from "../../../service/cypherService";
import TableData from "./Table";
import HTTPService from "../../../service/HTTPService";
import { EndpointService } from "../../../service/endpointService";
import { Schema } from "./Schema";
import { toast } from "react-toastify";
import DeleteModal from "./DeleteModal";
import { debounce } from "lodash";

const MyQuestList = () => {
  const [createQuestModal, setCreateQuestModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tableItem, setTableItem] = useState([]);
  const [parentSearchTxtBoxValue, setParentSearchTxtBoxValue] = useState("");
  const [initialValues, setInitialValues] = useState(Schema.initialValues);
  const [validationSchema] = useState(
    Schema.validationSchema
  );
  const [editAssignDate, setEditAssignDate] = useState("");
  const [editDeadLineDate, setEditDeadLineDate] = useState("");
  const [questUpdate, setQuestUpdate] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [rowData, setRowData] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterParams, setFilterParams] = useState({
    "quest": null,
    "questStatus": null
  });
  const filterStatusList = [
    {
      name: 'All'
    },
    {
      name: 'Assign Quest'
    },
    {
      name: 'In Progress'
    },
    {
      name: 'Active'
    },
    {
      name: 'Expired'
    }
  ]

  const navigate = useNavigate();

  useEffect(() => {
    cypherService.setSessionStorage('selectedQuestId', null);
    cypherService.setSessionStorage('selectedGradeId', null);
    getAllGrades();
  }, []);

  // GetTableItem
  const getTableItem = (tempFilterParams, searchText, tempStart, tempLimit) => {
    setLoader(true);
    let apiUrl = EndpointService.questList + `${tempFilterParams.quest}/range?quest-status=${tempFilterParams.questStatus}&start=${tempStart}&limit=${tempLimit}`;
    if (searchText !== '') {
      apiUrl = apiUrl + `&search=${searchText}`;
    }
    HTTPService.get(apiUrl, null)
      .then((response) => {
        const items = response.data;
        setTableItem(items);
        if (response.status.success == "Success") {
          setLoader(false);
          setTotalCount(response.totalResults);
          let expandedList = cypherService.getSessionStorage('expandQuestList');
          if (expandedList == 'null') {
            cypherService.setSessionStorage("expandQuestCount", response.totalResults);
            cypherService.setSessionStorage("expandQuestList", JSON.stringify(response.data));
          }
        }
        if (response.status.success == "Fail") {
          setLoader(false);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  // expandCard
  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
    setTimeout(() => {
      navigate("/teacher/dashboard");
    }, 200);
  };

  const getAllGrades = () => {
    const apiUrl = EndpointService.getAllGrade;
    let tempFilterList = [];
    HTTPService.get(apiUrl, null)
      .then((response) => {
        Object.entries(response.data).map((item) => {
          tempFilterList.push({
            gradeId: item[0],
            gradeName: item[1]
          })
        })
        setFilterList([...tempFilterList]);
        setGradeFilterList(tempFilterList);
      })
      .catch((error) => {
        setGradeFilterList(tempFilterList);
        throw error;
      });
  }

  const setGradeFilterList = (temp_FilterList) => {
    let tempFilterList = temp_FilterList;
    let tempGradeFilterList = [];
    let tempGradeIdList = [];
    tempFilterList && tempFilterList.map((item, index) => {
      if (!tempGradeIdList.includes(item.gradeId)) {
        tempGradeFilterList.push(item);
        tempGradeIdList.push(item.gradeId)
      }
    })
    setFilterList([...tempGradeFilterList]);
    let tempSelectedGrade = cypherService.getSessionStorage('selectedFilterGrade');
    let tempSelectedGradeStatus = cypherService.getSessionStorage('selectedFilterGradeStatus');
    let tempListGrade = cypherService.getSessionStorage('selectedQuestListFilterGrade');
    let tempListGradeStatus = cypherService.getSessionStorage('selectedQuestListFilterGradeStatus');
    let tempFilterParams = {
      "quest": tempListGrade !== "null" ? tempListGrade : tempSelectedGrade,
      "questStatus": tempListGradeStatus !== "null" ? tempListGradeStatus :tempSelectedGradeStatus
    }
    setFilterParams({ ...tempFilterParams });
    let tempQuestCount = cypherService.getSessionStorage('expandQuestCount') ? cypherService.getSessionStorage('expandQuestCount') : null;
    let tempQuestList = cypherService.getSessionStorage('expandQuestList') ? JSON.parse(cypherService.getSessionStorage('expandQuestList')) : null;
    if ((tempQuestCount && tempQuestList) && (tempListGrade === "null" && tempListGradeStatus === "null")) {
      setTableItem(tempQuestList);
      setTotalCount(tempQuestCount);
    }
    else {
      getTableItem(tempFilterParams, parentSearchTxtBoxValue, start, limit);
    }
  }

  const onSearch = debounce((event) => {
    let tempFilterParams = filterParams;
    setParentSearchTxtBoxValue(event.target.value);
    getTableItem(tempFilterParams, event.target.value, start, limit);
  }, 1000);

  const columns = [
    {
      Header: "Name of Quest",
      accessor: "questName"
    },
    {
      Header: "Questions",
      accessor: "questionsCount",
    },
    {
      Header: "Grade",
      accessor: "gradeName",
    },
    {
      Header: "Status",
      accessor: "questStatus",
      Cell: (row) => {
        if (row.cell.value === 'Assign Quest') {
          if (row.cell.row.original.questionsCount === 0) {
            return (
              <span className="quest-name color-blue" onClick={() => navigateToQuestions(row)}>Add Questions</span>
            )
          }
          else {
            return (
              <span className="quest-name color-blue" onClick={() => navigateToAssignQuest(row)}>{row.cell.value}</span>
            )
          }
        }
        if (row.cell.value === 'Active') {
          return (
            <span className="color-green" onClick={() => navigateToAssignQuest(row)}>{row.cell.value}</span>
          )
        }
        if (row.cell.value === 'Expired') {
          return (
            <span className="color-red" onClick={() => navigateToAssignQuest(row)}>{row.cell.value}</span>
          )
        }
        else {
          return (<span className="quest-name" onClick={() => navigateToAssignQuest(row)}>{row.cell.value}</span>)
        }
      }
    },
    {
      Header: "Asssigned",
      accessor: "assignedDate",
    },
    {
      Header: "Deadline",
      accessor: "deadLine",
    },
    {
      Header: "",
      accessor: "action",
      Cell: (row) => (
        <DropdownButton
          align="end"
          title={<MdOutlineMoreVert />}
          id="dropdown-menu-align-end"
          variant="link"
          className="td-action"
        >
          <Dropdown.Item
            eventKey="1"
            onClick={(e) => {
              navigateToCreateQuestion(e);
            }}
          >
            <MdList /> Questions
          </Dropdown.Item>
          <Dropdown.Item
            disabled={getEditQuestionDisabled(row)}
            eventKey="2"
            onClick={(e) => {
              getQuestById(e);
            }}
          >
            <MdFileCopy /> Edit Details
          </Dropdown.Item>
          <Dropdown.Item
            className="trash-icon"
            eventKey="3"
            disabled={getDeleteDisabled(row)}
            onClick={() => {
              deleteQuest(row);
            }}
          >
            <MdDelete /> Delete
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  const getEditQuestionDisabled = (rowData) => {
    return (rowData.row.original.questStatus && rowData.row.original.questStatus !== 'Assign Quest')
  }

  const getDeleteDisabled = (rowData) => {
    return !(rowData.row.original.questStatus && (rowData.row.original.questStatus === 'Assign Quest' || (rowData.row.original.questStatus === 'Assign Quest' && rowData.row.original.questionsCount === 0)))
  }

  const data = tableItem;

  // Create Quest
  const createQuest = () => {
    setCreateQuestModal(true);
    setQuestUpdate(false);
    setEditAssignDate("")
    setEditDeadLineDate("")
    setInitialValues({
      ...initialValues,
      questName: "",
      grade: {
        gradeId: "",
      },
      subject: {
        subjectId: "",
      },
      assignedDate: "",
      deadLine: "",
      questId: "",
    });
  };

  const navigateToQuestions = (rowData) => {
    let questId = rowData.row.original.questId;
    cypherService.setSessionStorage('expandQuestCount', null);
    cypherService.setSessionStorage('expandQuestList', null); 
    cypherService.setSessionStorage('selectedQuestId', questId);
    navigate("/teacher/create-questions");
  }

  const navigateToCreateQuestion = (tableRowData) => {
    let questId = tableRowData.target.closest("tr").getAttribute("questid");
    cypherService.setSessionStorage('expandQuestCount', null);
    cypherService.setSessionStorage('expandQuestList', null);
    cypherService.setSessionStorage('selectedQuestId', questId);
    navigate("/teacher/create-questions");
  }

  const navigateToAssignQuest = (tableRowData) => {
    cypherService.setSessionStorage('selectedQuestId', tableRowData.row.original.questId);
    cypherService.setSessionStorage('selectedGradeId', tableRowData.row.original.gradeId);
    cypherService.setSessionStorage('expandQuestCount', null);
    cypherService.setSessionStorage('expandQuestList', null);
    navigate("/teacher/assign-quest");
  }

  // Delete Quest
  const deleteQuest = (row) => {    
    setRowData(row)
    setDeleteModalShow(true);
  };

  // getQuestById
  const getQuestById = (e) => {
    let questId = e.target.closest("tr").getAttribute("questid");
    let apiUrl = EndpointService.getQuestById + questId;
    HTTPService.get(apiUrl, null)
      .then((response) => {
        const data = response.data;
        if (response.status.success == "Success") {
          setQuestUpdate(true);
          setEditAssignDate(new Date(data.assignedDate));
          setEditDeadLineDate(new Date(data.deadLine));
          setInitialValues({
            ...initialValues,
            questName: data.questName,
            grade: {
              gradeId: data.grade.gradeId,
            },
            subject: {
              subjectId: data.subject.subjectId,
            },
            assignedDate: data.assignedDate,
            deadLine: data.deadLine,
            questId: data.questId,
          });
          setCreateQuestModal(true);
        }

        if (response.status.success == "Fail") {
          toast.error(response.status.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.status.message);
        throw error;
      });
  };

  const getFilterName = () => {
    return filterList && filterList.map((item) => {
      if (filterParams.quest.toString() === item.gradeId.toString()) {
        return item.gradeName
      }
    })
  }

  const getFilterStatusName = () => {
    return filterStatusList && filterStatusList.map((item) => {
      if (filterParams.questStatus === item.name) {
        return item.name
      }
    })
  }

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams.quest !== value.gradeId) {
      tempFilterParams.quest = value.gradeId;
      cypherService.setSessionStorage('selectedQuestListFilterGrade', value.gradeId);
    }
    setFilterParams({ ...tempFilterParams })
    getTableItem(tempFilterParams, parentSearchTxtBoxValue, start, limit)
  }

  const filterQuestStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams.questStatus !== value.name) {
      tempFilterParams.questStatus = value.name;
      cypherService.setSessionStorage('selectedQuestListFilterGradeStatus', value.name);
    }
    setFilterParams({ ...tempFilterParams })
    getTableItem(tempFilterParams, parentSearchTxtBoxValue, start, limit)
  }

  return (
    <>
      <Header />
      <section className="main-container">
        <div className="dashboard-container">
        {loader ? <div className="loader"><Spinner animation="grow" variant="primary" /></div> : null}
          <Card className="my-quests my-quests-list expand">          
            <div className="list-header">
              <Breadcrumb className="m-0">
                <Breadcrumb.Item onClick={() => navigate('/teacher/dashboard')}>
                  <MdHome /> Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item active>My Quest</Breadcrumb.Item>
              </Breadcrumb>
              <div className="filter-wrapper">
                <DropdownButton
                  id="dropdown-grade"
                  size="sm"
                  title={getFilterStatusName()}
                  variant="light"
                  className="filter status-filter"
                >
                  {
                    filterStatusList && filterStatusList.map((item) => (
                      <Dropdown.Item onClick={() => filterQuestStatusChange(item)}>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          value={item.name}
                          checked={item.name === filterParams.questStatus}
                        />
                        <span>{item.name}</span>
                      </Dropdown.Item>
                    ))
                  }
                </DropdownButton>
                <DropdownButton
                  id="dropdown-grade"
                  size="sm"
                  title={getFilterName()}
                  variant="light"
                  className="filter"
                >
                  {
                    filterList && filterList.map((item) => (
                      <Dropdown.Item onClick={() => filterStatusChange(item)}>
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          value={item.gradeId}
                          checked={item.gradeId.toString() === filterParams.quest.toString()}
                        />
                        <span>{item.gradeName}</span>
                      </Dropdown.Item>
                    ))
                  }
                </DropdownButton>
              </div>
              <div className="search">
                <InputGroup>
                  <Form.Control
                    placeholder="Search"
                    onChange={(e) => onSearch(e)}
                  />
                  <InputGroup.Text>
                    <MdSearch />
                  </InputGroup.Text>
                </InputGroup>
                <Button
                  onClick={() => {
                    createQuest();
                  }}
                >
                  Create Quest
                </Button>
              </div>
            </div>
            <Card.Title className="p-0">
              <div
                className="expand"
                onClick={(e) => {
                  expandCard(e);
                }}
              >
                <MdOpenInFull />
              </div>
            </Card.Title>
            <Card.Body className="card-body">
              {
                data && data.length > 0 ? (
                  <TableData
                    columns={columns}
                    filterParams={filterParams}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    start={start}
                    setStart={setStart}
                    limit={limit}
                    setLimit={setLimit}
                    getTableItem={getTableItem}
                    totalCount={totalCount}
                    data={data}
                    parentSearchTxtBoxValue={parentSearchTxtBoxValue}
                    setParentSearchTxtBoxValue={setParentSearchTxtBoxValue}
                  />
                ) : (
                  <div className="no-data">
                    No quest found
                  </div>
                )
              }
            </Card.Body>
          </Card>
          <CreateQuestModal
            type="questList"
            show={createQuestModal}
            onHide={() => setCreateQuestModal(false)}
            initialValues={initialValues}
            validationSchema={validationSchema}
            editAssignDate={editAssignDate}
            editDeadLineDate={editDeadLineDate}
            questUpdate={questUpdate}
            setFilterParams={setFilterParams}
            getTableItem={getTableItem}
            setStart={setStart}
            setLimit={setLimit}
            setParentSearchTxtBoxValue={setParentSearchTxtBoxValue}
            loader={loader}
            setLoader={setLoader}
          />
          <DeleteModal
            show={deleteModalShow}
            onHide={() => setDeleteModalShow(false)}
            rowData={rowData}
            loader={loader}
            setLoader={setLoader}
            setFilterParams={setFilterParams}
            getTableItem={getTableItem}
            setStart={setStart}
            setLimit={setLimit}
            setParentSearchTxtBoxValue={setParentSearchTxtBoxValue}
          />
        </div>
      </section>
    </>
  );
};

export default MyQuestList;
