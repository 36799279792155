import React, { useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  Spinner,
  Table,
} from "react-bootstrap";
import CreateQuestModal from "../myQuests/CreateQuestModal";
import { MdOpenInFull } from "react-icons/md";
import { Schema } from "../myQuests/Schema";
import moment from "moment";
import { cypherService } from "../../../service/cypherService";
import { useNavigate } from "react-router";

const MyQuests = (props) => {

  const [createQuestModal, setCreateQuestModal] = useState(false);
  const [initialValues] = useState(Schema.initialValues);
  const [validationSchema] = useState(
    Schema.validationSchema
  );
  const filterStatusList = [
    {
      name: 'All'
    },
    {
      name: 'Assign Quest'
    },
    {
      name: 'In Progress'
    },
    {
      name: 'Active'
    },
    {
      name: 'Expired'
    }
  ]

  const { loader, filterParams, questList, filterList, getDashboardDetails, setLoader } = props;

  const navigate = useNavigate();

  const expandCard = (e) => {
    let elem = e.target.parentElement.closest(".card");
    if (elem.classList.contains("expand")) {
      elem.classList.remove("expand");
    } else {
      elem.classList.add("expand");
    }
    setTimeout(() => {
      cypherService.setSessionStorage('expandQuestCount', props.questCount);
      cypherService.setSessionStorage('expandQuestList', JSON.stringify(props.questList));
      navigate("/teacher/my-quest");
    }, 200);
  };

  const filterStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams.quest !== value.gradeId) {
      tempFilterParams.quest = value.gradeId;
      cypherService.setSessionStorage('selectedFilterGrade', value.gradeId)
      getDashboardDetails(tempFilterParams, 'quest');
    }
  }

  const filterQuestStatusChange = (value) => {
    let tempFilterParams = filterParams
    if (filterParams.questStatus !== value.name) {
      tempFilterParams.questStatus = value.name;
      cypherService.setSessionStorage('selectedFilterGradeStatus', value.name)
      getDashboardDetails(tempFilterParams, 'quest');
    }
  }

  const getTableData = () => {
    if (questList && questList.length === 0) {
      return (
        <div className="no-data">
          No data found
        </div>
      );
    }
    else {
      return (
        <Table responsive className="homework-table dashboard-table">
          <thead>
            <tr>
              <th>Quest Name</th>
              <th>Grade</th>
              <th>Status</th>
              <th>Students</th>
              <th>Assigned</th>
              <th>Deadline</th>
            </tr>
          </thead>
          <tbody>
            {
              questList && questList.map((item, index) => (
                <tr>
                  <td>{item.questName}</td>
                  <td>{item.gradeName}</td>
                  <td className="status">
                    <span className={`${item.questStatus.toLowerCase().replaceAll(' ', '')}`}>{item.questStatus}</span>
                  </td>
                  <td>{item.studentsCount}</td>
                  <td>{moment(item.assignedDate).format("DD/MM/YYYY")}</td>
                  <td>{moment(item.deadLine).format("DD/MM/YYYY")}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      )
    }
  }

  const getFilterStatusName = () => {
    return filterStatusList && filterStatusList.map((item) => {
      if (filterParams.questStatus == item.name) {
        return item.name
      }
    })
  }

  const getFilterName = () => {
    return filterList && filterList.map((item) => {
      if (filterParams.quest == item.gradeId) {
        return item.gradeName
      }
    })
  }

  const navigateToAssignQuest = () => {
    cypherService.setSessionStorage('selectedGradeId', filterParams.quest)
    navigate("/teacher/assign-quest");
  }
  
  return (
    <>
      <Card className="my-quests">
        <Card.Title>
          <span>My Quests</span>
          <DropdownButton
            id="dropdown-grade"
            size="sm"
            title={getFilterStatusName()}
            variant="outline-secondary"
            className="filter status-filter"
          >
            {
              filterStatusList && filterStatusList.map((item) => (
                <Dropdown.Item onClick={() => filterQuestStatusChange(item)}>
                  <Form.Check
                    type="radio"
                    aria-label="radio 1"
                    value={item.name}
                    checked={item.name === filterParams.questStatus}
                  />
                <span>{item.name}</span>
                </Dropdown.Item>
              ))
            } 
          </DropdownButton>
          <Button onClick={() => setCreateQuestModal(true)} size="sm">
            Create Quest
          </Button>
          <Button onClick={() => navigateToAssignQuest()} size="sm">
            Manage Quest
          </Button>
          <div
            className="expand"
            onClick={(e) => {
              expandCard(e);
            }}
          >
            <MdOpenInFull />
          </div>
          <DropdownButton
            id="dropdown-grade"
            size="sm"
            title={getFilterName()}
            variant="light"
            className="filter"            
          >
            {            
              filterList && filterList.map((item) => (
                <Dropdown.Item onClick={() => filterStatusChange(item)}>
                  <Form.Check
                    type="radio"
                    aria-label="radio 1"
                    value={item.gradeId}
                    checked={item.gradeId == filterParams.quest}
                  />
                  <span>{item.gradeName}</span>
                </Dropdown.Item>
              ))
            }             
          </DropdownButton>
        </Card.Title>
        <Card.Body className="card-body">
          {
            loader.quest ? (
              <div className="loader"><Spinner animation="grow" variant="primary" /></div>
            ) : (
              getTableData()
            )
          }
        </Card.Body>
      </Card>
      {
        createQuestModal && (
          <CreateQuestModal
            type="dashboard"
            filterParams={filterParams}
            getDashboardDetails={getDashboardDetails}
            loader={loader}
            setLoader={setLoader}
            show={createQuestModal}
            onHide={() => setCreateQuestModal(false)}
            initialValues={initialValues}
            validationSchema={validationSchema}
          />
        )
      }
    </>
  );
};

export default MyQuests;
